@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "~swiper/css";
@import "~swiper/css/a11y";
@import "~swiper/css/autoplay";
@import "~swiper/css/controller";
@import "~swiper/css/effect-fade";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

@layer base {
  body {
    @apply font-sans text-white bg-secondary;
  }

  main {
    counter-reset: section;
  }

  main > section {
    counter-increment: section;
  }

  main > section .counter:before {
    content: counter(section, decimal-leading-zero);
  }

  main > section:nth-child(6) .counter-incremented:before {
    counter-reset: section 7;
    content: counter(section, decimal-leading-zero);
  }

  #main-menu {
    counter-reset: nav;
  }

  #main-menu > li {
    counter-increment: nav;
  }

  #main-menu > li .counter:before {
    content: counter(nav, decimal-leading-zero);
  }
}

@layer components {
  .prose {
    @apply max-w-3xl 
      mx-auto
      prose-h2:text-4xl
      prose-h2:font-serif
      prose-h2:font-medium
      prose-h2:mb-8
      prose-h3:text-xl
      prose-h3:font-semibold
      prose-h3:text-primary
      prose-h3:mb-8
      prose-h4:text-xl
      prose-h4:font-semibold
      prose-h4:mb-8
      prose-p:mb-8
      prose-ul:pl-0
      prose-li:list-none
      prose-li:before:content-['\25A0']
      prose-li:before:text-primary
      prose-li:before:mr-4;
  }
}

@layer utilities {
  .clip-path-header-xl {
    clip-path: inset(0 0 0 42.5%);
  }

  .clip-path-header-lg {
    clip-path: inset(0 0 0 40%);
  }

  .clip-path-header {
    clip-path: inset(0 0 0 33.3%);
  }
}

.prose strong.highlighted {
  @apply text-xl font-medium;
}

.prose blockquote {
  @apply pl-12 border-primary not-italic;
}

.prose blockquote strong {
  @apply text-primary-light text-xl font-semibold;
}

.prose a {
  @apply underline-offset-4 decoration-primary hover:text-primary visited:text-primary visited:hover:text-primary transition;
}

.prose a.button {
  @apply inline-block py-4 px-6 lg:py-5 lg:px-12 rounded-full border font-sans font-medium uppercase text-sm no-underline transition-colors duration-300;
}

.prose a.button.button--primary:not(.inverse) {
  @apply border-primary bg-primary text-primary-dark;
}

.prose a.button.button--primary.inverse {
  @apply border-primary bg-white text-default;
}

.prose p:first-child {
  margin-top: 0;
}

.prose p:last-child {
  margin-bottom: 0;
}

.prose-inverse {
  @apply text-white;
}

.prose-inverse a {
  @apply text-white underline underline-offset-4 decoration-primary hover:text-primary visited:text-secondary-light visited:hover:text-primary transition-colors;
}

/* reCaptcha */
.grecaptcha-badge {
  visibility: hidden;
  right: 0 !important;
}
